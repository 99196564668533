<template>
  <div class="profile" v-if="profile">
    <div class="header">
      <img
        src="file.enc"
        alt="Foto de perfil de Robert Almeida"
        class="profile-image"
      />
      <h1>{{ profile.name }}</h1>
      <p class="position">{{ profile.position }}</p>

      <!-- Botão de Troca de Idioma com Bandeiras -->
      <button @click="toggleLanguage" class="language-button">
        <img
          v-if="isEnglish"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png"
          alt="Bandeira dos EUA"
          class="flag-icon"
        />
        <img
          v-else
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/1920px-Flag_of_Brazil.svg.png"
          alt="Bandeira do Brasil"
          class="flag-icon"
        />
      </button>
    </div>

    <div class="content">
      <div class="contact">
        <h2>{{ this.isEnglish ? "Contact" : "Contato" }}</h2>
        <p><strong>Email:</strong> {{ profile.email }}</p>
        <p>
          <strong>{{ this.isEnglish ? "Phone" : "Telefone" }}:</strong>
          {{ profile.phone }}
        </p>
        <p>
          <strong>GitHub:</strong>
          <a :href="profile.github" target="_blank">{{ profile.github }}</a>
        </p>
        <p>
          <strong>{{ this.isEnglish ? "Location" : "Localização" }}:</strong>
          {{ profile.location }}
        </p>
        <p>
          <strong
            >{{ this.isEnglish ? "Remote Work" : "Trabalho Remoto" }}:</strong
          >
          {{ profile.remote }}
        </p>
      </div>

      <div class="section">
        <h2>{{ this.isEnglish ? "Skills" : "Habilidades" }}</h2>
        <ul class="skills">
          <li v-for="skill in profile.skills" :key="skill">{{ skill }}</li>
        </ul>
      </div>

      <div class="section">
        <h2>{{ this.isEnglish ? "Certifications" : "Certificações" }}</h2>
        <ul>
          <li v-for="cert in profile.certifications" :key="cert">
            <a href="cert.pdf" target="_blank">{{ cert }}</a>
          </li>
        </ul>
      </div>

      <div class="section">
        <h2>{{ this.isEnglish ? "Projects" : "Projetos" }}</h2>
        <ul>
          <li v-for="project in profile.projects" :key="project.name">
            <strong>
              <a :href="project.link" target="_blank">{{ project.name }}</a>
            </strong>
            <p>{{ project.description }}</p>
          </li>
        </ul>
      </div>

      <div class="section">
        <h2>{{ this.isEnglish ? "About Robert" : "Sobre Robert" }}</h2>
        <p class="about-text">{{ profile.about }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEnglish: false,
      profiles: {
        pt: {
          name: "Robert Rocha Soares de Almeida",
          position: "Desenvolvedor | Desde 2019",
          email: "robert.almeida1597@gmail.com",
          phone: "11995540438",
          github: "https://github.com/RobertAlmeida",
          location: "ITU",
          remote: "Sim",
          skills: [
            "TypeScript",
            "Git",
            "JavaScript",
            "Node.js",
            "AWS S3",
            "MySQL",
            "Express",
            "Linux",
            "TDD",
            "PHP",
            "API",
            "AWS RDS (Relational Database Service)",
            "AWS EC2 (Elastic Compute Cloud)",
            "Python",
            "S.O.L.I.D",
            "Docker",
            "Vue.js",
            "Go",
            "Machine Learning",
            "Data Analysis",
            "Deep Learning",
            "SQL",
            "Pandas",
            "NumPy",
            "Matplotlib",
          ],
          certifications: ["English CEFR: B1 Intermediário"],
          projects: [
            {
              name: "Flyn",
              link: "https://flyn-new.vercel.app/",
              description: "Plataforma para gerenciamento de urls.",
            },
            {
              name: "Gerador de CPF ou CNPJ",
              link: "https://www.gerarcpfcnpj.com.br/",
              description:
                "Ferramenta para ajudar a testar formulários gerando CPF ou CNPJ",
            },
          ],
          about: `Olá! Se você está procurando um desenvolvedor back-end, deixe-me apresentar-me. Atualmente, estou cursando Ciência de Dados, o que me permite aplicar análises avançadas e habilidades de processamento de dados em soluções tecnológicas. Tenho experiência em linguagens de programação como Node.js, PHP e TypeScript, além de familiaridade com frameworks populares como Vue.js, React e Angular.

Meu foco principal é a construção de soluções de back-end escaláveis e robustas para aplicativos da web e móveis. Isso inclui a criação de APIs RESTful, gerenciamento de bancos de dados, integração de sistemas e implementação de segurança e autenticação. Também tenho conhecimento em manipulação e análise de dados, o que pode agregar valor ao desenvolvimento de soluções baseadas em dados.

Sou capaz de trabalhar em colaboração com equipes de front-end e designers UX/UI para garantir a melhor experiência para o usuário final. Além disso, estou comprometido em seguir as melhores práticas de codificação e testes automatizados para assegurar a qualidade do meu trabalho.

Se você está procurando um desenvolvedor back-end com uma visão orientada por dados e que possa ajudá-lo a desenvolver soluções escaláveis, seguras e eficientes para sua aplicação da web ou móvel, entre em contato comigo para discutir seus requisitos. Estou sempre pronto para ajudá-lo a levar sua ideia para o próximo nível.`,
        },
        en: {
          name: "Robert Rocha Soares de Almeida",
          position: "Developer | Since 2019",
          email: "robert.almeida1597@gmail.com",
          phone: "11995540438",
          github: "https://github.com/RobertAlmeida",
          location: "ITU",
          remote: "Yes",
          skills: [
            "TypeScript",
            "Git",
            "JavaScript",
            "Node.js",
            "AWS S3",
            "MySQL",
            "Express",
            "Linux",
            "TDD",
            "PHP",
            "API",
            "AWS RDS (Relational Database Service)",
            "AWS EC2 (Elastic Compute Cloud)",
            "Python",
            "S.O.L.I.D",
            "Docker",
            "Vue.js",
            "Go",
            "Machine Learning",
            "Data Analysis",
            "Deep Learning",
            "SQL",
            "Pandas",
            "NumPy",
            "Matplotlib",
          ],
          certifications: ["English CEFR: B1 Intermediate"],
          projects: [
            {
              name: "Flyn",
              link: "https://flyn-new.vercel.app/",
              description: "URL management platform.",
            },
            {
              name: "CPF or CNPJ Generator",
              link: "https://www.gerarcpfcnpj.com.br/",
              description: "Tool to help test forms by generating CPF or CNPJ",
            },
          ],
          about: `Hello! If you are looking for a back-end developer, let me introduce myself. I am currently pursuing a degree in Data Science, which allows me to apply advanced analytics and data processing skills to technology solutions. I am proficient in programming languages such as Node.js, PHP, and TypeScript, as well as familiar with popular frameworks such as Vue.js, React, and Angular.

My primary focus is on building scalable and robust back-end solutions for web and mobile applications. This includes creating RESTful APIs, managing databases, integrating systems, and implementing security and authentication. I also have knowledge of data manipulation and analysis, which can add value to the development of data-driven solutions.

I am able to work collaboratively with front-end teams and UX/UI designers to ensure the best experience for the end-user. Additionally, I am committed to following best practices in coding and automated testing to ensure the quality of my work.

If you are looking for a data-driven back-end developer who can help you develop scalable, secure, and efficient solutions for your web or mobile application, contact me to discuss your requirements. I am always ready to help you take your idea to the next level.`,
        },
      },
    };
  },
  computed: {
    profile() {
      return this.isEnglish ? this.profiles.en : this.profiles.pt;
    },
  },
  methods: {
    toggleLanguage() {
      this.isEnglish = !this.isEnglish;
    },
    addSurpriseEffect() {
      const header = document.querySelector(".header");
      header.addEventListener("mouseover", () => {
        header.style.transition =
          "transform 0.3s ease, background-color 0.3s ease";
        header.style.transform = "scale(1.05)";
        header.style.backgroundColor = "#00b4d8";
      });
      header.addEventListener("mouseleave", () => {
        header.style.transform = "scale(1)";
        header.style.backgroundColor = "#0077b6";
      });
    },
  },
  mounted() {
    this.addSurpriseEffect();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

.profile {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  color: #333;
}

.header {
  text-align: center;
  padding: 20px;
  background-color: #0077b6;
  color: white;
  border-radius: 10px;
}

.header h1 {
  font-size: 2.5rem;
}

.position {
  font-size: 1.2rem;
  color: #edf2f4;
}

.section {
  margin-top: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-text {
  text-align: justify;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #555;
}

h2 {
  color: #0077b6;
  border-bottom: 2px solid #00b4d8;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skills li {
  padding: 10px 15px;
  background: #00b4d8;
  color: white;
  border-radius: 20px;
}

.language-button {
  border: none;
  padding: 10px;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.language-button:hover {
  background: #edf2f4;
}
.flag-icon {
  width: 25px;
  height: auto;
}
</style>
